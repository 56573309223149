const faqs = [
  {
    question: "1. ¿Qué cubre un seguro de viaje internacional?",
    answer: (
      <div>
        <p>El seguro de viaje internacional puede cubrir una amplia gama de situaciones, incluyendo: <br/> <br/>

- Cancelación de viaje: Si necesitas cancelar tu viaje debido a una enfermedad, lesión o cualquier otra razón cubierta por la póliza, el seguro de viaje puede reembolsar los costos no reembolsables de tu viaje. <br/> <br/>
- Demora o cancelación de vuelo: Si su vuelo se retrasa o se cancela debido a una razón cubierta por la póliza, como una huelga de aerolíneas o mal tiempo, el seguro de viaje puede cubrir los costos adicionales, como alojamiento y comidas. <br/> <br/>
- Pérdida de equipaje: Si su equipaje se pierde, es robado o dañado durante su viaje, el seguro de viaje puede cubrir los costos de reemplazo de los artículos. <br/> <br/>
- Emergencia médica: Si sufre una enfermedad o lesión durante su viaje, el seguro de viaje puede cubrir los costos médicos, incluidas las visitas al médico, los tratamientos y los medicamentos recetados.  <br/> <br/>
- Evacuación médica de emergencia: Si necesita ser evacuado de emergencia a un hospital debido a una enfermedad o lesión, el seguro de viaje puede cubrir los costos de transporte médico. <br/> <br/>
- Repatriación de restos: Si fallece durante su viaje, el seguro de viaje puede cubrir los costos de repatriación de sus restos.

</p>
      </div>
    ),
  },
  {
    question: "2. ¿Cómo comprar un seguro de viaje internacional?",
    answer: (
      <div>
        <p>Es importante hacer una investigación, hablar con un asesor y comparar coberturas antes de comprar una póliza. Asegúrate de leer la letra pequeña y entender lo que está cubierto y qué no lo está. También debes considerar el nivel de cobertura que necesitás y cualquier requisito de visa o documentación necesaria para comprar una póliza de seguro de viaje internacional.<br/> <br/> Algunos países requieren de forma obligatoria que los turistas tengan un seguro de viaje antes de ingresar al país. Además, algunos programas de intercambio educativo y programas de voluntariado en el extranjero pueden requerir un seguro de viaje como requisito para la participación. Es importante investigar las políticas y requisitos específicos del país y programa de su interés.<br/> <br/> Una vez que hayas determinado qué tipo de seguro de viaje internacional necesitás, podés comprar una póliza en línea o a través de un agente de seguros. Asegúrate de proporcionar información precisa sobre tu viaje y cualquier condición médica preexistente para garantizar que tu póliza proporcione la cobertura adecuada.

 </p> 

      </div>
    ),
  },
  {
    question: "3. ¿Cuánto cuesta el seguro de viaje internacional?",
    answer: (
      <div>
        <p>
        El costo del seguro de viaje internacional va a variar según la duración del viaje, el destino, la edad del viajero y el nivel de cobertura deseado. En general, el costo puede variar entre el 4% y el 8% del costo total del viaje. Sin embargo, en ASSIST 365 ofrecemos pólizas básicas hasta pólizas de alta gama que cubren una amplia gama de situaciones. <br/> <br/> Recordá que el costo del seguro de viaje es un pequeño precio a pagar por la tranquilidad y protección financiera en caso de una emergencia durante tu viaje. En lugar de arriesgarse a enfrentar gastos médicos no contemplados y otros costos imprevistos en el extranjero, el seguro de viaje internacional puede proporcionar calma y un resguardo financiero durante todo el viaje.

 
        </p> 
      </div>
    ),
  },

];

export default faqs;
